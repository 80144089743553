import { colors } from '@styles/colors'
import { typography } from '@styles/typography'
import {
  Accordion,
  AccordionOverrides,
  AccordionProps,
  SharedProps,
} from 'baseui/accordion'
import { padding } from 'polished'
import { forwardRef } from 'react'
import { useOverrides } from '../hooks/use-overrides'
import { Icon } from '../icon'

export const BaseAccordion = forwardRef<Accordion, AccordionProps>(
  ({ children, overrides, ...props }, ref) => {
    return (
      <Accordion
        accordion
        overrides={useOverrides<AccordionOverrides<SharedProps>>(
          {
            ToggleIcon: {
              component: ({ $expanded }: { $expanded: boolean }) => (
                <Icon
                  size={16}
                  color={colors.black}
                  type={$expanded ? 'ChevronUp' : 'ChevronDown'}
                />
              ),
            },
            Header: {
              style: {
                ...padding('24px', '4px'),
                ...typography.LabelM,
                fontWeight: 400,
                userSelect: 'none',
              },
            },
            Content: {
              style: {
                ...typography.LabelS,
              },
            },
          },
          overrides
        )}
        // ref={ref}
        {...props}
      >
        {children}
      </Accordion>
    )
  }
)
