import Link from 'next/link'
import { BaseButton, BaseButtonProps } from './base-button'

interface LinkButtonProps extends BaseButtonProps {
  href: string
  target?: '_blank' | '_self' | '_parent' | '_top'
}

export const LinkButton = ({
  children,
  href,
  target,
  ...props
}: LinkButtonProps) => (
  <Link href={href} passHref>
    <a target={target}>
      <BaseButton {...props}>{children}</BaseButton>
    </a>
  </Link>
)
