import { NextPage, NextPageContext } from 'next'
import { BaseCell } from '@components/base-cell'
import { BaseGrid } from '@components/base-grid'
import { colors, ColorType } from '@styles/colors'
import { border, borderRadius, rounded } from '@styles/utils'
import { styled, useStyletron } from 'styletron-react'
import { boxShadow } from '@styles/box-shadow'
import { LandingFooter } from '@components/footer'
import { Logo } from '@components/logo'
import { Routes } from '@lib/routes'
import { zIndexes } from '@styles/z-indexes'
import { LinkButton } from '@components/base-button'
import {
  HeadingL,
  HeadingM,
  HeadingS,
  HeadingXL,
  HeadingXS,
  LabelL,
  LabelS,
  ParagraphM,
  ParagraphS,
} from '@components/base-typography'
import Image from 'next/image'
import Link from 'next/link'
import { Icon, IconType } from '@components/icon'
import { mediaQuery } from '@styles/media-query'
import { padding } from 'polished'
import { ALIGNMENT } from 'baseui/layout-grid'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { BaseAccordion, BasePanel } from '@components/base-accordion'
import { typography } from '@styles/typography'
import { Spaces } from '@components/hooks/use-space'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import { breakpoints } from '@styles/breakpoints'
import mainImage from 'public/images/landing/main.webp'
import mockup1 from 'public/images/landing/mockup-1.webp'
import mockup3 from 'public/images/landing/mockup-3.webp'
import landing3 from 'public/images/landing/landing-3.webp'
import ontol3d from 'public/images/landing/ontol-3d.webp'
import ontolImage1 from 'public/images/landing/ontol-image-1.webp'
import ontolImage2 from 'public/images/landing/ontol-image-2.webp'
import ontolImage3 from 'public/images/landing/ontol-image-3.webp'
import check from 'public/images/icons/check.webp'
import forbidden from 'public/images/icons/forbidden.webp'
import { imageLoader } from '@lib/utils/image-loader'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import ReactGA from 'react-ga'

const LANDING_HEADER_HEIGHT = 60

const Home: NextPage = () => {
  const [css] = useStyletron()
  // const router = useRouter()
  // console.log(userAgent)
  // const mobileOS = (userAgent?.includes('iPhone') || userAgent?.includes('Mac')) === true ? 'iOS' : 'Android'
  // const appstoreURL = APPSTORE_URLS[mobileOS]
  // const isProd = process.env.NEXT_PUBLIC_STAGE === 'prod'

  // useEffect(() => {
  //   if (router.asPath.includes('role')) {
  //     router.replace(`?role=nonmember`, Routes.ROOT, { shallow: true })
  //   }
  // }, [router])

  const [isMobile, setIsMobile] = useState(false)

  const router = useRouter()

  useEffect(() => {
    const userAgent = navigator.userAgent

    if (/android/i.test(userAgent)) {
      setIsMobile(true)
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      setIsMobile(true)
    }
  }, [])

  useEffect(() => {
    ReactGA.initialize('G-XRVM0Z9R72')
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        position: 'relative',
        overflowX: 'hidden',
      })}
    >
      <header
        className={css({
          position: 'fixed',
          top: 0,
          width: '100%',
          boxSizing: 'border-box',
          zIndex: zIndexes.header,
          backgroundColor: colors.white,
          ...boxShadow('borderBottom'),
        })}
      >
        <BaseGrid gridMaxWidth={1040}>
          <BaseCell span={[4, 8, 12]}>
            <div
              className={css({
                height: LANDING_HEADER_HEIGHT + 'px',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              })}
            >
              <Logo size="xs" />
              <nav
                className={css({
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '4px',
                  [mediaQuery.TABLET]: {
                    columnGap: '12px',
                  },
                  textAlign: 'right',
                })}
              >
                {isMobile && (
                  <LinkButton href={Routes.APPSTORE_DYNAMIC_LINK} $size="m">
                    앱 다운로드
                  </LinkButton>
                )}
              </nav>
            </div>
          </BaseCell>
        </BaseGrid>
      </header>
      <main
        className={css({
          flex: '1 1 auto',
          position: 'relative',
          marginTop: LANDING_HEADER_HEIGHT + 'px',
        })}
      >
        <Section space={0}>
          <BaseGrid gridMaxWidth={1040} spaceTop={[96, 96, 140]}>
            <BaseCell span={[4, 8, 12]}>
              <div
                className={css({
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  rowGap: '48px',
                  columnGap: '32px',
                  [mediaQuery.TABLET]: {
                    flexDirection: 'row',
                  },
                })}
              >
                <span
                  className={css({
                    [mediaQuery.MOBILE]: {
                      marginTop: '-20px',
                    },
                    [mediaQuery.TABLET]: {
                      marginTop: '40px',
                    },
                    [mediaQuery.DESKTOP]: {
                      marginTop: '48px',
                    },
                  })}
                >
                  <HeadingXL
                    $style={{
                      [mediaQuery.MOBILE]: {
                        textAlign: 'center',
                      },
                      [mediaQuery.TABLET]: {
                        whiteSpace: 'pre',
                        textAlign: 'left',
                      },
                    }}
                  >
                    검사 결과지, <br />
                    쉽고 빠르게{' '}
                    <span
                      className={css({
                        [mediaQuery.TABLET]: { display: 'none' },
                      })}
                    >
                      <br />
                    </span>
                    해석 받으세요
                  </HeadingXL>
                  <HeadingXS
                    weight={400}
                    marginTop="32px"
                    $style={{
                      [mediaQuery.MOBILE]: {
                        textAlign: 'center',
                      },
                      [mediaQuery.TABLET]: {
                        textAlign: 'left',
                      },
                    }}
                  >
                    MRI, CT, 초음파, 조직 검사.
                    <br />
                    어려운 의학용어를{' '}
                    <span
                      className={css({
                        [mediaQuery.TABLET]: { display: 'none' },
                      })}
                    >
                      <br />
                    </span>
                    이해하기 편한 한글로 바꿔드려요.
                  </HeadingXS>
                  <div
                    className={css({
                      display: 'flex',
                      [mediaQuery.TABLET]: {
                        display: 'flex',
                        justifyContent: 'flex-start',
                      },
                      [mediaQuery.MOBILE]: {
                        display: isMobile ? 'none' : 'flex',
                        justifyContent: 'center',
                      },
                      columnGap: '16px',
                      marginTop: '48px',
                      flexWrap: 'wrap',
                      rowGap: '16px',
                    })}
                  >
                    <AppStoreButton type="android" />
                    <AppStoreButton type="ios" />
                  </div>
                  <div
                    className={css({
                      [mediaQuery.MOBILE]: {
                        display: 'block',
                      },
                      [mediaQuery.TABLET]: {
                        display: 'none',
                      },

                      textAlign: 'center',
                    })}
                  >
                    {isMobile && (
                      <LinkButton
                        href={Routes.APPSTORE_DYNAMIC_LINK}
                        spaceTop={40}
                        width={180}
                      >
                        앱 다운로드
                      </LinkButton>
                    )}
                  </div>
                </span>
                <span
                  className={css({
                    width: '100%',
                    paddingRight: '28px',
                    boxSizing: 'border-box',
                    display: 'flex',
                    justifyContent: 'center',
                    ...rounded(32),
                    overflow: 'hidden',
                    [mediaQuery.TABLET]: {
                      paddingRight: '0px',
                      width: '360px',
                    },
                    [mediaQuery.DESKTOP]: {
                      width: '463px',
                    },
                  })}
                >
                  <Image
                    src={mainImage}
                    alt="ontol"
                    width={463}
                    height={763}
                    // sizes="(max-width: 768px) 360px, (max-width: 1136px) 360px, 360px"
                    priority
                    loader={({ src }) => imageLoader(src)}
                  />
                </span>
              </div>
            </BaseCell>
          </BaseGrid>
        </Section>

        <section
          className={css({
            backgroundColor: colors.lightBlue,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            zIndex: 1,
            boxSizing: 'border-box',
            marginTop: '-78px',
            ...padding('66px', '16px'),
            [mediaQuery.TABLET]: {
              marginTop: '-68px',
              ...padding('100px', '16px'),
            },
            [mediaQuery.DESKTOP]: {
              marginTop: '-134px',
              ...padding('100px', '16px'),
            },
          })}
        >
          <div
            className={css({
              [mediaQuery.MOBILE]: {
                display: 'none',
              },
              [mediaQuery.TABLET]: {
                display: 'block',
              },
            })}
          >
            <iframe
              src="https://www.youtube.com/embed/WG_dMADG9LA"
              title="온톨 프로모션"
              style={{
                border: 'none',
                width: '100vw',
                height: 'calc(100vw * 9 / 16)',
                maxWidth: 880,
                maxHeight: 495,
                paddingBottom: 44,
              }}
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />

            <HeadingM color={colors.white} align="center" whiteSpace="pre-wrap">
              언제 어디서나,{' '}
              <br
                className={css({
                  [mediaQuery.TABLET]: {
                    display: 'none',
                  },
                })}
              />
              빠른 시간 내에
              <br />
              검사결과지 내용을 쉽게 설명해드려요.
            </HeadingM>
          </div>
          <div
            className={css({
              [mediaQuery.MOBILE]: {
                display: 'block',
              },
              [mediaQuery.TABLET]: {
                display: 'none',
              },
            })}
          >
            <HeadingS color={colors.white} align="center" whiteSpace="pre-wrap">
              <iframe
                src="https://www.youtube.com/embed/WG_dMADG9LA"
                title="온톨 프로모션"
                style={{
                  border: 'none',
                  width: '100vw',
                  height: 'calc(100vw * 9 / 16)',
                  paddingBottom: 20,
                }}
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </HeadingS>
            <HeadingS color={colors.white} align="center" whiteSpace="pre-wrap">
              언제 어디서나, <br />
              빠른 시간 내에, <br />
              검사결과지 내용을 쉽게 설명해드려요.
            </HeadingS>
          </div>
        </section>

        <Section space={[128, 140, 180]} bg={colors.gray50}>
          <BaseGrid gridMaxWidth={1040}>
            <BaseCell span={[4, 5, 7]}>
              <div
                className={css({
                  [mediaQuery.TABLET]: {
                    paddingRight: '54px',
                  },
                  [mediaQuery.DESKTOP]: {
                    paddingRight: '20px',
                  },
                })}
              >
                <SectionTextArea
                  icon="Camera"
                  label="결과지 업로드"
                  title="사진을 찍어 올려주세요"
                  description="해석받고 싶은 결과지를 사진을 찍어서 올리기만 하면 끝.
               개인정보는 가리고, 쉽고 안전하게 관리하세요."
                />
                <ul
                  className={css({
                    marginTop: '64px',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '48px',
                    [mediaQuery.TABLET]: {
                      marginTop: '96px',
                    },
                    [mediaQuery.DESKTOP]: {
                      marginTop: '128px',
                    },
                  })}
                >
                  <li>
                    <Image
                      src={check}
                      width={40}
                      height={40}
                      alt="check"
                      placeholder="blur"
                      priority
                    />
                    <LabelL marginTop="16px" weight={700}>
                      이런 결과지는 가능해요.
                    </LabelL>
                    <ParagraphM marginTop="12px" color={colors.gray600}>
                      CT, MRI, X-Ray, 초음파, PET 등 의료 영상 검사 결과지,
                      조직검사 결과지, 수술기록지 등.
                    </ParagraphM>
                  </li>
                  <li>
                    <Image
                      src={forbidden}
                      width={40}
                      height={40}
                      alt="check"
                      placeholder="blur"
                      priority
                    />
                    <LabelL marginTop="16px" weight={700}>
                      이것들은 어려워요.
                    </LabelL>
                    <ParagraphM marginTop="12px" color={colors.gray600}>
                      혈액검사와 같이 수치 해석이 필요한 검사, 유전체검사와 같이
                      전문가의 해석이 필요한 항목은 용어에 대한 제한적인
                      설명만이 가능합니다.
                    </ParagraphM>
                  </li>
                </ul>
              </div>
            </BaseCell>
            <BaseCell
              span={[4, 3, 4]}
              skip={[0, 0, 1]}
              spaceTop={[64, 64, 0, 0]}
            >
              <span
                className={css({
                  display: 'flex',
                  justifyContent: 'center',
                  [mediaQuery.TABLET]: {
                    justifyContent: 'flex-end',
                  },
                })}
              >
                <span
                  className={css({
                    display: 'flex',
                    maxWidth: '336px',
                    [mediaQuery.TABLET]: {
                      maxWidth: '254px',
                    },
                    [mediaQuery.TABLET]: {
                      maxWidth: '100%',
                    },
                  })}
                >
                  <Image
                    src={mockup1}
                    alt="ontol"
                    priority
                    loader={({ src }) => imageLoader(src)}
                  />{' '}
                </span>
              </span>
            </BaseCell>
          </BaseGrid>
        </Section>

        <Section space={[128, 140, 180]}>
          <BaseGrid gridMaxWidth={1040}>
            <BaseCell span={[4, 3, 4]} skip={[0, 0, -0.22]}>
              <span
                className={css({
                  display: 'flex',
                  justifyContent: 'center',
                  [mediaQuery.TABLET]: {
                    justifyContent: 'flex-end',
                  },
                })}
              >
                <span
                  className={css({
                    display: 'flex',
                    maxWidth: '336px',
                    [mediaQuery.TABLET]: {
                      maxWidth: '254px',
                    },
                    [mediaQuery.TABLET]: {
                      maxWidth: '100%',
                    },
                  })}
                >
                  <Image
                    src={mockup3}
                    alt="ontol"
                    priority
                    loader={({ src }) => imageLoader(src)}
                  />
                </span>
              </span>
            </BaseCell>
            <BaseCell
              span={[4, 5, 7]}
              skip={[0, 0, 1]}
              spaceTop={[64, 0, 0, 0]}
              align={ALIGNMENT.center}
            >
              <div
                className={css({
                  [mediaQuery.TABLET]: {
                    paddingLeft: '54px',
                  },
                  [mediaQuery.DESKTOP]: {
                    paddingLeft: '20px',
                  },
                })}
              >
                <SectionTextArea
                  icon="ClockBold"
                  label="평균 3분 이내 해석 제공 (1장 기준)"
                  title="해석 결과를 확인하세요"
                  description="사진을 올리고 잠시 기다리시면, 검사결과지에 대한 해석을 살펴볼 수 있어요. 중요한 내용들을 모아서 요약하고, 어려운 단어를 클릭하시면 설명을 확인할 수 있어요."
                  subDescription="(AI를 통한 해석으로, 부정확한 내용이 포함될 수 있습니다. 참고용으로만 사용하세요.)"
                />
              </div>
            </BaseCell>
          </BaseGrid>
        </Section>

        <Section space={[128, 160, 184]} bg={colors.gray50}>
          <BaseGrid gridMaxWidth={1040}>
            <BaseCell span={[4, 8, 12]}>
              <HeadingXL $style={{ wordBreak: 'keep-all' }}>
                정확하게, 안전하게, 빠르게
              </HeadingXL>
              <div
                className={css({
                  marginTop: '72px',
                  [mediaQuery.TABLET]: {
                    marginTop: '96px',
                  },
                })}
              >
                <FlexGrid
                  flexGridColumnCount={[1, 1, 2]}
                  flexGridColumnGap={['72px', '72px', '96px', '96px']}
                  flexGridRowGap={['72px', '72px', '96px', '96px']}
                >
                  <FlexGridItem>
                    <IconWithText
                      icon="Verified"
                      title="번역기보다 정확한 해석"
                      color={colors.blue500}
                      bg={colors.blue100}
                      description="의료텍스트에 최적화된 AI 모델을 통해서 일반 번역기보다 더 정확한 해석을 살펴볼 수 있습니다."
                      subDescription="(단, 아직 초기단계로 잘못된 해석이 나올 수 있으며 지속적으로 개선이 진행되고 있습니다.)"
                    />
                  </FlexGridItem>
                  <FlexGridItem>
                    <IconWithText
                      icon="Security"
                      title="안전한 보안 시스템"
                      color={colors.purple500}
                      bg={colors.purple100}
                      description="올려주신 정보들은 의료 정보 보안기준(HIPAA)을 준수하는 보안 클라우드 환경에서 체계적으로 관리됩니다."
                    />
                  </FlexGridItem>
                  <FlexGridItem>
                    <IconWithText
                      icon="HourglassBold"
                      title="빠른 해석 제공"
                      color={colors.orange500}
                      bg={colors.orange100}
                      description="검사지 한장당 평균 3분 이내에, 검사지에 대한 전문 해석과 함께 주요 내용 요약, 어려운 용어에 대한 설명을 제공합니다."
                    />
                  </FlexGridItem>
                  <FlexGridItem>
                    <IconWithText
                      icon="BookmarkAdd"
                      title="의료 용어 해석"
                      color={colors.green500}
                      bg={colors.green100}
                      description="해석 내용에 어려운 의료 용어가 있다면 더 자세한 설명을 볼 수 있습니다."
                    />
                  </FlexGridItem>
                </FlexGrid>
              </div>
            </BaseCell>
          </BaseGrid>
        </Section>

        <Section space={[96, 128, 148]}>
          <BaseGrid gridMaxWidth={1040}>
            <BaseCell span={[4, 8, 12]}>
              <div
                className={css({
                  display: 'flex',
                  columnGap: '72px',
                  flexDirection: 'column',
                  rowGap: '64px',
                  [mediaQuery.TABLET]: {
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  },
                  [mediaQuery.DESKTOP]: {
                    columnGap: '104px',
                  },
                })}
              >
                <div
                  className={css({
                    display: 'flex',
                    justifyContent: 'center',
                    minWidth: '264px',
                    width: '100%',
                    [mediaQuery.DESKTOP]: {
                      maxWidth: '468px',
                    },
                  })}
                >
                  <Image
                    src={ontol3d}
                    alt="ontol"
                    width={768}
                    height={640}
                    objectFit="cover"
                    // sizes="(max-width: 768px) 360px, (max-width: 1136px) 360px, 360px"
                    priority
                  />
                </div>
                <span
                  className={css({
                    flex: '1 1 auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  })}
                >
                  <HeadingL $style={{ wordBreak: 'keep-all' }}>
                    어려운 의료 영상까지,
                    <br /> 이해하기 쉽도록
                  </HeadingL>
                  <HeadingXS weight={400} marginTop="32px">
                    CT, MRI 영상을 보내주시면, 종양의 모습을 이해하기 쉽게
                    살펴볼 수 있도록 3D 이미지로 제작해서 보여드려요.
                    <br />
                    <br />
                    아래 버튼을 클릭해 자세한 정보를 확인해보세요.
                  </HeadingXS>
                  <LinkButton
                    href="https://3d.ontol.com"
                    target="_blank"
                    spaceTop={40}
                    width={180}
                  >
                    더 알아보기
                  </LinkButton>
                </span>
              </div>
            </BaseCell>
          </BaseGrid>
        </Section>

        {/* 사용자 리뷰 */}
        <Section space={[96, 128, 160]} bg={colors.gray50}>
          <BaseGrid gridMaxWidth={1040} spaceBottom={[48, 64, 80]}>
            <BaseCell span={[4, 8, 12]}>
              <HeadingXL>온톨 유저들의 이야기</HeadingXL>
            </BaseCell>
          </BaseGrid>
          <BaseGrid gridMaxWidth={1040}>
            <BaseCell span={[4, 8, 12]}>
              <div className={css({ display: 'flex' })}>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={20}
                  pagination={{
                    clickable: true,
                  }}
                  grabCursor
                  navigation
                  modules={[Pagination, Navigation]}
                  className="mySwiper"
                  breakpoints={{
                    [breakpoints.TABLET]: {
                      slidesPerView: 2,
                    },
                    [breakpoints.DESKTOP]: {
                      slidesPerView: 3,
                    },
                  }}
                  style={{
                    // @ts-ignore
                    '--swiper-navigation-color': 'rgba(51, 51, 51, 0.5)',
                  }}
                >
                  {REVIEWS.map(({ id, userId, title, description }) => (
                    <SwiperSlide key={id}>
                      <div
                        className={css({
                          height: '100%',
                          minHeight: '264px',
                          ...padding('32px', '32px', '56px'),
                          boxSizing: 'border-box',
                          ...rounded(16),
                          backgroundColor: colors.gray50,
                        })}
                      >
                        <LabelS>{userId}</LabelS>
                        <LabelS weight={700} marginTop="8px">
                          {title}
                        </LabelS>
                        <ParagraphM marginTop="24px">{description}</ParagraphM>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>{' '}
            </BaseCell>
          </BaseGrid>
        </Section>

        <Section space={[96, 128, 140]}>
          <BaseGrid gridMaxWidth={1040}>
            <BaseCell span={[4, 8, 12]}>
              <HeadingXL>
                어렵고 부족했던{' '}
                <br
                  className={css({
                    [mediaQuery.TABLET]: {
                      display: 'none',
                    },
                  })}
                />
                의료 정보
                <br />
                온톨에서 함께 나누세요
              </HeadingXL>
            </BaseCell>
            <BaseCell
              span={[4, 8, 10]}
              skip={[0, 0, 1]}
              spaceTop={[32, 64, 72]}
            >
              <span className={css({ display: 'flex' })}>
                <Image
                  src={ontolImage1}
                  alt="ontol"
                  width={1040}
                  height={578}
                  // sizes="(max-width: 768px) 736px, (max-width: 1136px) 1040px, 864px"
                  priority
                />
              </span>
            </BaseCell>
            <BaseCell span={[4, 6, 6]} skip={[0, 0, 0]} spaceTop={[64, 72, 96]}>
              <span
                className={css({
                  display: 'flex',
                  paddingRight: 'calc((100% + 16px) / 4)',
                  [mediaQuery.TABLET]: {
                    paddingRight: 0,
                  },
                })}
              >
                <Image
                  src={ontolImage2}
                  alt="ontol"
                  width={776}
                  height={1042}
                  // sizes="(max-width: 768px) 548px, (max-width: 1136px) 776px, 512px"
                  priority
                />
              </span>
              <HeadingL marginTop="32px">
                치료 경험부터{' '}
                <br
                  className={css({
                    [mediaQuery.TABLET]: {
                      display: 'none',
                    },
                  })}
                />
                일상의 고민까지, <br />
                조금은 더 쉽게 알 수 있도록
              </HeadingL>
            </BaseCell>
            <BaseCell
              span={[3, 6, 6]}
              skip={[1, 2, 0]}
              spaceTop={[64, 72, 96]}
              style={{
                [mediaQuery.TABLET]: {
                  paddingLeft: '48px',
                  boxSizing: 'border-box',
                },
              }}
            >
              <Image
                src={ontolImage3}
                alt="ontol"
                width={701}
                height={468}
                sizes="(max-width: 768px) 548px, (max-width: 1136px) 701px, 464px"
                priority
              />
              <HeadingM marginTop="32px" $style={{ wordBreak: 'keep-all' }}>
                환자와 보호자들의 고민을
                <br />
                언제 어디서나 쉽고 빠르게
              </HeadingM>
            </BaseCell>
          </BaseGrid>
        </Section>

        <Section space={[72, 96, 120]} bg={colors.gray50}>
          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingLeft: '16px',
              paddingRight: '16px',
              [mediaQuery.TABLET]: {},
            })}
          >
            <HeadingXL align="center">
              이제,
              <br />
              온톨에서 도와드릴게요
            </HeadingXL>
            {/* {isProd ? ( */}
            <div
              className={css({
                display: 'flex',
                columnGap: '16px',
                marginTop: '32px',
                rowGap: '16px',
                justifyContent: 'center',
                flexWrap: 'wrap',
              })}
            >
              <AppStoreButton type="android" />
              <AppStoreButton type="ios" />
            </div>
            {/* ) : (
              <LinkButton
                href={Routes.AUTH_SIGNUP}
                spaceTop={[32, 48, 48]}
                width={210}
              >
                무료로 회원가입
              </LinkButton>
            )} */}
          </div>
        </Section>

        <Section space={[72, 72, 128]}>
          <BaseGrid gridMaxWidth={1040}>
            <BaseCell span={[4, 8, 4]}>
              <HeadingL>
                궁금한 것이
                <br />
                있으신가요?
              </HeadingL>
            </BaseCell>
            <BaseCell span={[4, 8, 8]} spaceTop={[32, 32, 0]}>
              <BaseAccordion
                stateReducer={(_, nextState) => nextState}
                overrides={{
                  ToggleIcon: {
                    component: ({ $expanded }: { $expanded: boolean }) => (
                      <Icon
                        type={
                          $expanded
                            ? 'RemoveCircleLineBold'
                            : 'AddCircleLineBold'
                        }
                      />
                    ),
                  },
                  Header: {
                    style: {
                      ...padding('20px', '4px'),
                      ...typography.HeadingXS,
                      fontWeight: 400,
                      userSelect: 'none',
                      color: colors.gray700,
                      ...border(colors.gray100, ['0px', '0', '1px', '0']),
                    },
                  },
                  Content: {
                    style: {
                      ...typography.ParagraphM,
                      color: colors.gray700,
                      ...padding('24px', '0px'),
                      ...border(colors.gray100, ['0px', '0', '1px', '0']),
                      whiteSpace: 'pre-wrap',
                    },
                  },
                  PanelContainer: {
                    style: {
                      ...border('transparent'),
                    },
                  },
                }}
              >
                {[
                  {
                    id: 1,
                    title: '결과지 해석은 어떻게 이루어지나요?',
                    description:
                      '이미지 상에서 글자를 추출해내고, 추출된 글자를 바탕으로 자체 해석 알고리즘을 통해 번역 결과가 제공되는 형태입니다. 이 때, 사진이 잘 못찍히거나, 워터마크 등으로 글자가 잘 추출되지 않을 수 있으며, 그에 따라 해석 내용에도 오류가 생길 수 있습니다. 또한 현재 초기 버전으로, 아직 모든 범위에서 좋은 결과가 나오지는 않을 수 있으며 특히 혈액검사, 심혈관계 질환 등의 분야에서는 아직 더 많은 개선이 필요하니 참고해 주시기 바랍니다.',
                  },
                  {
                    id: 2,
                    title: '결과지는 안전하게 처리되나요?',
                    description:
                      '네, 결과지에 포함된 개인정보는 해석 제공과 해석 서비스 개선을 위해 활용되는 것 이외에 별도의 동의 없이는 외부 기관에 절대 제공되지 않으며, 데이터는 안전한 보안 클라우드 시스템을 통해 체계적으로 관리되고 있습니다. 검사 결과지 이미지 상에서 노출하고 싶지 않은 정보는, 서류상에서 직접 가리거나 크롭 도구로 해당 부위를 제외한 알고 싶은 부분만 선택(크롭)하여 업로드하실 수 있습니다.',
                  },
                  {
                    id: 3,
                    title: '자동해석 결과를 믿어도 되나요?',
                    description:
                      '자동해석 결과는 데이터를 학습한 인공지능 모델에 의한 것으로, 정확하지 않은 결과가 있을 수 있으며 반드시 참고용으로만 사용해 주시기 바랍니다. 의학적으로 더 넓은 소견을 얻고자 하신다면 병원 방문을 통해 주치의나 타 의료인에 방문 상담을 통해 얻으셔야 합니다. 또한 자동해석 서비스는 이미지 상에서 글자를 추출해내고, 추출된 글자를 바탕으로 자체 해석 알고리즘을 통해 번역 결과가 제공되는 형태입니다. 이 때, 사진이 똑바로 선명하게 찍히지 않거나, 결과지 상의 병원 로고 워터마크 등으로 글자가 잘 추출되지 않을 수 있으며, 그에 따라 해석 내용에도 오류가 생길 수 있습니다. 현재는 초기 버전으로, 아직 모든 범위에서 좋은 결과가 나오지는 않을 수 있으며 특히 혈액검사, 심혈관계 질환 등의 분야에서는 아직 더 많은 개선이 필요하니 사용에 있어 반드시 참고해 주시기 바랍니다.',
                  },
                  {
                    id: 4,
                    title: '1:1 문의는 어떻게 하나요?',
                    description:
                      'contact@ontol.com 이메일을 통해 1:1 문의를 접수하고 있습니다.',
                  },
                ].map(({ id, title, description }) => (
                  <BasePanel title={title} key={id}>
                    {description}
                  </BasePanel>
                ))}
              </BaseAccordion>
            </BaseCell>
          </BaseGrid>
        </Section>
      </main>
      <LandingFooter />
    </div>
  )
}

Home.getInitialProps = async ({ req }: NextPageContext) => {
  const userAgent = req ? req.headers['user-agent'] : navigator.userAgent
  return { userAgent }
}

export default Home

interface AppStoreButtonProps {
  type: 'ios' | 'android'
}

const AppStoreButton = ({ type }: AppStoreButtonProps) => {
  const [css] = useStyletron()

  const props = {
    ios: {
      label: 'App Store',
      icon: 'Apple',
      href: Routes.APP_STORE,
    },
    android: {
      label: 'Play Store',
      icon: 'GooglePlay',
      href: Routes.PLAY_STORE,
    },
  }[type]

  return (
    <Link href={props.href}>
      <a
        target="_blank"
        className={css({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          columnGap: '6px',
          ...border(colors.gray200),
          ...borderRadius(32),
          width: '163px',
          height: '56px',
          boxSizing: 'border-box',
          backgroundColor: colors.white,
          cursor: 'pointer',
        })}
      >
        <Icon type={props.icon as IconType} />
        <LabelL whiteSpace="pre" weight={700}>
          {props.label}
        </LabelL>
      </a>
    </Link>
  )
}

interface SectionTextAreaProps {
  icon: IconType
  label: string
  title: string
  description: string
  subDescription?: string
}

const SectionTextArea = ({
  icon,
  label,
  title,
  description,
  subDescription,
}: SectionTextAreaProps) => {
  const [css] = useStyletron()

  return (
    <>
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          columnGap: '8px',
        })}
      >
        <Icon type={icon} size={26} color={colors.blue400} />
        <HeadingXS color={colors.blue400}>{label}</HeadingXS>
      </div>
      <HeadingL marginTop="32px">{title}</HeadingL>
      <HeadingXS weight={400} marginTop="32px">
        {description}
      </HeadingXS>
      {subDescription && (
        <ParagraphS marginTop="20px" color={colors.gray400}>
          {subDescription}
        </ParagraphS>
      )}
    </>
  )
}

interface IconWithTextProps {
  icon: IconType
  title: string
  color: ColorType
  bg: ColorType
  description: string
  subDescription?: string
}

const IconWithText = ({
  icon,
  title,
  color,
  bg,
  description,
  subDescription,
}: IconWithTextProps) => {
  const [css] = useStyletron()

  return (
    <>
      <div
        className={css({
          backgroundColor: bg,
          width: '80px',
          height: '80px',
          ...rounded(16),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        })}
      >
        <Icon type={icon} size={40} color={color} />
      </div>
      <HeadingM marginTop="32px">{title}</HeadingM>
      <HeadingXS marginTop="24px" weight={400}>
        {description}
      </HeadingXS>
      {subDescription && (
        <ParagraphS marginTop="24px" color={colors.gray400}>
          {subDescription}
        </ParagraphS>
      )}
    </>
  )
}

interface SectionProps {
  bg?: ColorType
  space: Spaces
}

const Section = styled('section', ({ bg, space }: SectionProps) => {
  if (!space) return {}

  if (typeof space === 'number') {
    return {
      boxSizing: 'border-box',
      backgroundColor: bg,
      paddingTop: space + 'px',
      paddingBottom: space + 'px',
    }
  }

  const [mobile, tablet, desktop] = space

  return {
    boxSizing: 'border-box',
    backgroundColor: bg,
    paddingTop: mobile + 'px',
    paddingBottom: mobile + 'px',

    [mediaQuery.TABLET]: {
      paddingTop: tablet + 'px',
      paddingBottom: tablet + 'px',
    },

    [mediaQuery.DESKTOP]: {
      paddingTop: desktop + 'px',
      paddingBottom: desktop + 'px',
    },
  }
})

const REVIEWS = [
  {
    id: 1,
    userId: '이***',
    title: '구글 플레이스토어 리뷰',
    description:
      '빠르고 자세한 해석 감사합니다! 어플 내에 짧은 감사의 글을 남길 수 있는 공간이 없어서 여기에 남깁니다. 귀사의 노력에 감사의 마음을 전합니다.',
  },
  {
    id: 2,
    userId: 'J***',
    title: '구글 플레이스토어 리뷰',
    description:
      '전문적인 분야라 일반인들이 잘 이해가 되질 않아 따로 약사(어떤 항목은 모름)나 검색을 해서 겨우 몇 개는 보았지만 아주 불편하고 귀찮은게 아니었는데 이 앱을 만나게 된것이 큰 행운인 것 같습니다. 개발자께 깊은 감사를 드립니다.',
  },
  {
    id: 3,
    userId: '박***',
    title: '구글 플레이스토어 리뷰',
    description:
      '대학병원급 병원에서 며칠을 기다리고 진료봐도 대충 알려주고 자세히 알려주지 않아 많이 답답했었는데 이렇게 도움받게 됐네요. 너무 감사합니다. 이렇게 좋은일하시니 복 많이 받으실 거예요.',
  },
  {
    id: 4,
    userId: '송***',
    title: '구글 플레이스토어 리뷰',
    description:
      '정말 좋은 앱입니다. 번역기로는 이해하기 힘든 부분들이 해결되니 너무 좋습니다^^ 감사합니다.',
  },
]
