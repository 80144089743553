interface ImageLoaderArgs {
  w?: number
  h?: number
  q?: number
  f?: string
}

export const imageLoader = (
  src: string,
  { w, h, q = 75, f = 'webp' }: ImageLoaderArgs = {}
) => {
  let url = `${src}?q=${q}&f=${f}`

  if (w) {
    url += `&w=${w}`
  }

  if (h) {
    url += `&h=${h}`
  }

  return url
}
