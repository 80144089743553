import { Panel, PanelProps } from 'baseui/accordion'
import { forwardRef } from 'react'

export const BasePanel = forwardRef<Panel, PanelProps>(
  ({ children, ...props }, ref) => {
    return (
      <Panel {...props} ref={ref}>
        {children}
      </Panel>
    )
  }
)
