import { forwardRef } from 'react'
import { Button, ButtonProps, ButtonOverrides } from 'baseui/button'
import {
  borderColor,
  borderRadius,
  borderStyle,
  borderWidth,
  margin,
} from 'polished'
import { useOverrides } from '../hooks/use-overrides'
import { ButtonSize, ButtonType } from './button.interface'
import { useButtonStyle } from './use-button-style'
import { Icon, IconType } from '../icon'
import { Spaces, useSpace } from '../hooks/use-space'

export interface BaseButtonProps extends ButtonProps {
  $size?: ButtonSize
  $type?: ButtonType
  fluid?: boolean
  disabled?: boolean
  leading?: IconType
  trailing?: IconType
  spaceTop?: Spaces
  spaceBottom?: Spaces
  width?: number
}

export const BaseButton = forwardRef<HTMLButtonElement, BaseButtonProps>(
  (
    {
      children,
      overrides,
      fluid,
      leading,
      trailing,
      $size = 'l',
      $type = 'primary',
      spaceTop,
      spaceBottom,
      width,
      ...props
    },
    ref
  ) => {
    const {
      backgroundColor,
      hoverBackgroundColor,
      color,
      hoverColor,
      disabledBackgroundColor,
      disabledColor,
      border,
      hoverBorder,
      size,
    } = useButtonStyle($type, $size)
    const { marginTop, marginBottom } = useSpace({ spaceTop, spaceBottom })

    return (
      <Button
        overrides={useOverrides<ButtonOverrides>(
          {
            BaseButton: {
              style: {
                ...size,
                ...marginTop,
                ...marginBottom,
                color,
                backgroundColor,
                width: width ? `${width}px` : fluid ? '100%' : 'auto',
                boxSizing: 'border-box',
                fontWeight: 500,
                wordBreak: 'keep-all',
                whiteSpace: 'pre',
                position: 'relative',
                ...borderRadius('left', '48px'),
                ...borderRadius('right', '48px'),
                ...borderWidth('1px'),
                ...borderStyle('solid'),
                ...borderColor(border),
                ':active': {
                  backgroundColor,
                },
                ':hover': {
                  color: hoverColor,
                  backgroundColor: hoverBackgroundColor,
                  ...borderColor(hoverBorder),
                },
                ':disabled': {
                  color: disabledColor,
                  backgroundColor: disabledBackgroundColor,
                  ...borderColor('transparent'),
                  ':hover': {
                    color: disabledColor,
                    backgroundColor: disabledBackgroundColor,
                    ...borderColor('transparent'),
                  },
                },
              },
            },
            StartEnhancer: {
              style: {
                marginRight: '4px',
              },
            },
            EndEnhancer: {
              style: {
                marginLeft: '4px',
              },
            },
            LoadingSpinnerContainer: {
              style: {
                ...margin('0px'),
                height: size.lineHeight,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            },
          },
          overrides
        )}
        startEnhancer={
          leading && (
            <Icon
              type={leading}
              size={$size === 'l' ? 16 : 12}
              color={props.disabled ? disabledColor : color}
            />
          )
        }
        endEnhancer={
          trailing && (
            <Icon
              type={trailing}
              size={$size === 'l' ? 16 : 12}
              color={props.disabled ? disabledColor : color}
            />
          )
        }
        {...props}
        ref={ref}
      >
        {children}
      </Button>
    )
  }
)
