import { colors } from '@styles/colors'
import { padding } from 'polished'
import { ButtonSize, ButtonType } from './button.interface'

export const useButtonStyle = ($type: ButtonType, $size: ButtonSize) => {
  const backgroundColor = {
    primary: colors.blue400,
    secondary: colors.blue50,
    tertiary: colors.white,
    danger: colors.red400,
    warning: colors.red50,
    text: colors.white,
  }[$type]

  const hoverBackgroundColor = {
    primary: colors.blue500,
    secondary: colors.blue100,
    tertiary: colors.blue50,
    danger: colors.red500,
    warning: colors.red100,
    text: colors.blue50,
  }[$type]

  const color = {
    primary: colors.white,
    secondary: colors.blue400,
    tertiary: colors.blue400,
    danger: colors.white,
    warning: colors.red400,
    text: colors.gray700,
  }[$type]

  const hoverColor = {
    primary: colors.white,
    secondary: colors.blue400,
    tertiary: colors.blue400,
    danger: colors.white,
    warning: colors.red400,
    text: colors.gray700,
  }[$type]

  const disabledBackgroundColor = {
    primary: colors.gray300,
    secondary: colors.gray100,
    tertiary: colors.white,
    danger: colors.gray300,
    warning: colors.gray100,
    text: colors.gray100,
  }[$type]

  const disabledColor = {
    primary: colors.white,
    secondary: colors.gray300,
    tertiary: colors.gray300,
    danger: colors.white,
    warning: colors.gray300,
    text: colors.gray300,
  }[$type]

  const border = {
    primary: 'transparent',
    secondary: 'transparent',
    tertiary: colors.blue100,
    danger: 'transparent',
    warning: 'transparent',
    text: 'transparent',
  }[$type]

  const hoverBorder = {
    primary: 'transparent',
    secondary: 'transparent',
    tertiary: colors.blue50,
    danger: 'transparent',
    warning: 'transparent',
    text: colors.gray50,
  }[$type]

  const size = {
    l: {
      fontSize: '18px',
      lineHeight: '22px',
      ...padding('11px', '19px'),
    },
    m: {
      fontSize: '16px',
      lineHeight: '20px',
      ...padding('7px', '19px'),
    },
    s: {
      fontSize: '14px',
      lineHeight: '18px',
      ...padding('4px', '19px'),
    },
  }[$size]

  return {
    backgroundColor,
    hoverBackgroundColor,
    color,
    hoverColor,
    disabledBackgroundColor,
    disabledColor,
    border,
    hoverBorder,
    size,
  }
}
